<template>
  <div
    ref="clientNumberRef"
    class="bg-noiseGradientGreen min-h-screen overflow-hidden py-28 lg:py-36"
  >
    <div class="relative min-h-screen transition opacity duration-1000" :class="isSectionVisible ? 'opacity-100' : 'opacity-0'">
      <div
        ref="stage"
        class="logo-wrapper absolute left-0 right-0 mx-auto h-full w-full max-w-[746px] lg:max-w-[1500px] min-h-screen"
      >
        <LazyLogoPattern v-if="isSectionVisible" ref="patternRef" class="absolute h-full w-full" />
      </div>
      <div
        :class="isSectionVisible ? 'opacity-100' : 'opacity-0'"
        class="pointer-events-none relative flex w-fit flex-col gap-8 px-10 pt-24 text-white lg:px-40 transition-opacity delay-1000 duration-1000"
      >
        <div
          v-for="item in data.statistics"
          :key="item.title"
          ref="numbersRef"
          class="numbers-wrapper container"
        >
          <div class="font-mono text-5xl-ub lg:text-8xl-ub">
            {{ item.number }}
          </div>
          <div class="text-2xl font-bold">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import type { SectionClientNumberListRecord } from '~/types/generated'
import { theme } from '#tailwind-config'

const breakpoints = useBreakpoints(theme.screens)
const isLG = breakpoints.greater('lg')

defineProps({
  data: {
    type: Object as PropType<SectionClientNumberListRecord>,
    default: () => {}
  }
})

const patternRef = ref<Ref<{ logoPattern: Element }> | null>(null)

const stage: Ref<HTMLDivElement | null> = ref(null)
const clientNumberRef = ref<HTMLElement | null>(null)
const numbersRef = ref<HTMLElement[] | null>(null)
let bgTween: null | any = null
let bgTweenOpposite: null | any = null
let tween: null | any = null

const isInitialAnimationDone = ref(false)
const isSectionVisible = ref(false)

const { stop } = useIntersectionObserver(
  stage,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      isSectionVisible.value = true
      if (isInitialAnimationDone.value) {
        bgTweenOpposite?.paused(false)
      } else {
        bgTween?.paused(false)
      }
    } else {
      bgTween?.paused(true)
      bgTweenOpposite?.paused(true)
    }
  },
  {
    threshold: 0.2
  }
)

watch(
  [isSectionVisible, patternRef],
  () => {
    const pattern = patternRef.value?.logoPattern
    const gElements = pattern?.children

    if (
      isSectionVisible.value &&
      clientNumberRef.value &&
      numbersRef.value &&
      stage.value &&
      pattern &&
      gElements
    ) {
      gsap.registerPlugin(ScrollTrigger)

      tween = gsap.timeline({
        scrollTrigger: {
          trigger: clientNumberRef.value,
          start: 'top-=40% top',
          end: 'bottom bottom',
          scrub: true,
          markers: false
        }
      })

      bgTween = gsap.timeline({
        onComplete: () => {
          isInitialAnimationDone.value = true
          bgTweenOpposite.play()
        }
      })

      bgTweenOpposite = gsap.timeline({
        paused: true,
        repeat: -1,
        repeatDelay: 1
      })

      numbersRef.value.forEach((layer, index) => {
        tween.from(
          layer,
          {
            x: (index + 2) * 30,
            opacity: 0,
            stagger: 2
          },
          index * 0.2
        )
      })

      tween.from(
        stage.value,
        {
          x: 60,
          opacity: 0,
          stagger: 2
        },
        -0.2
      )

      Array.from(gElements).forEach((el, index) => {
        bgTween.to(
          el,
          {
            color: 'white',
            stagger: 0.2
          },
          isLG.value ? index * 0.01 : index * 0.03
        )

        bgTweenOpposite.to(
          el,
          {
            color: 'black',
            stagger: 0.2
          },
          isLG.value ? index * 0.01 : index * 0.03
        )

        if (index % 4 === 0) {
          bgTweenOpposite.to(
            el,
            {
              color: 'white',
              delay: 1,
              stagger: 0.2
            },
            '>',
            isLG.value ? index * 0.01 : index * 0.03
          )
        } else {
          bgTweenOpposite.to(
            el,
            {
              color: 'white',
              delay: 3,
              stagger: 0.2
            },
            '>',
            isLG.value ? index * 0.01 : index * 0.03
          )
        }

        if (index % 4 === 0) {
          bgTweenOpposite.to(
            el,
            {
              color: 'black',
              delay: 3,
              stagger: 0.2
            },
            '>',
            isLG.value ? index * 0.01 : index * 0.03
          )
        } else {
          bgTweenOpposite.to(
            el,
            {
              color: 'black',
              delay: 3,
              stagger: 0.2
            },
            '>',
            isLG.value ? index * 0.01 : index * 0.03
          )
        }

        bgTweenOpposite.to(
          el,
          {
            color: 'white',
            delay: 3,
            stagger: 0.2
          },
          '>',
          isLG.value ? index * 0.01 : index * 0.03
        )
      })
    }
  },
  { deep: true }
)

onUnmounted(() => {
  tween?.kill()
  bgTween?.kill()
  bgTweenOpposite?.kill()
  stop()
})
</script>
